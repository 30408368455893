<template>
  <div>
    <el-dialog
      :visible.sync="createdDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeForbid"
      width="520px"
      class="created-business">
      <div slot="title" class="title">暂无关联企业，请先创建企业</div>
      <el-form :model="addCompanyForm" :rules="addCompanyFormRules" label-position="top" ref="addCompanyForm" style="padding: 0 20px;">
        <el-form-item label="企业名称" prop="company">
          <el-input placeholder="请输入" v-model.trim="addCompanyForm.company"></el-input>
        </el-form-item>
        <el-form-item label="请选择经营类型" prop="businessType" class="business-type">
          <el-radio-group v-model="addCompanyForm.businessType">
            <el-radio v-for="(item, index) in businessTypeList" :key="'type-'+index" :label="item.type" style="margin-bottom: 15px;">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="save(1)" size="small" type="primary">保 存</el-button>
        <el-button @click="save(2)" size="small" type="primary">保存并认证</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { BusinessTypeListEnum } from '@/enums/BusinessTypeListEnum.js';
import switchCompany from '@/utils/switchCompany.js';
import { createTenant } from '@/api/company.js';

export default {
  name: 'CreateBusiness',
  data () {
    return {
      createdDialogVisible: false,
      addCompanyForm: {
        company: '',
        businessType: '',
      },
      addCompanyFormRules: {
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        businessType: [
          { required: true, message: '请选择经营类型', trigger: 'change' },
        ],
      },
      businessTypeList: BusinessTypeListEnum,
    };
  },
  methods: {

    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),

    closeForbid () {
      this.$refs.addCompanyForm.resetFields();
      this.$emit('closeForbid');
    },

    checkRequired () {
      this.createdDialogVisible = false;
    },

    save (type) {
      this.$refs.addCompanyForm.validate().then(async valid =>{
        if (valid) {
          const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
          const list = await createTenant({
            id: employeeMsg.id,
            tenantNames: [this.addCompanyForm.company],
            businessType: this.addCompanyForm.businessType,
          });

          await switchCompany(list.body[0].tenantId);
          this.$message.success('企业创建成功');
          this.checkRequired();
          this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));

          this.$tabs.items = [];
          if (type === 1) {
            this.$router.push({
              path: '/workbench',
            });
            window.location.reload();
          }
          if (type === 2) {
            setTimeout(() => {
              this.$router.push({
                name: 'IDENTIFICATION',
              });
              window.location.reload();
            }, 2000);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .created-business {
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .el-form-item {
      /deep/.el-form-item__label {
        font-weight: 400;
        color: #1F2733;
      }
    }
    /deep/.el-radio__label {
      color: #606266;
      font-weight: 600;
    }
  }
</style>

