<template>
  <div>
    <ContentBlock >
      <div class="top" style="margin-bottom:10px;"><b>云应用</b></div>
      <div style="display: flex;" class="serviceContainer">
        <div  @click="handleViewMedial">
          <div class="ser_top">
            <el-image
              style="width: 40px; height: 40px;margin-right:10px"
              :src="require('@/assets/company/02.png')"
              fit="fill">
            </el-image>
            <div class="service_container_content">
              <div class="service_top">
                <b>医修保</b>
                <time v-if="tenantInfo.tenantServiceInfoStatus" :class="statusOb[tenantInfo.tenantServiceInfoStatus]">{{tenantInfo.tenantServiceInfoStatusDesc}}</time>
              </div>
              <div class="service_bottom">优质服务，5000+服务商的选择</div>
            </div>
          </div>
        </div>
        <div  @click="goYixie">
          <div class="ser_top">
            <el-image
              style="width: 40px; height: 40px;margin-right:10px"
              :src="require('@/assets/company/04.svg')"
              fit="fill">
            </el-image>
            <div class="service_container_content">
              <div class="service_top">
                <b>1械网</b>
                <!-- <time :class="['grey', 'grey', 'blue', 'green'][service.status]">{{['未开通', '已到期', '已开通', '使用中'][service.status]}}</time> -->
              </div>
              <div class="service_bottom">医械后市场电商平台</div>
            </div>
          </div>
        </div>
      </div>
    </ContentBlock>
    <ContentBlock >
      <div class="top" style="margin-bottom:10px;"><b>云插件</b></div>
      <div style="display: flex;" class="serviceContainer">
        <div @click="handleView">
          <div class="ser_top">
            <el-image
              style="width: 40px; height: 40px;margin-right:10px"
              :src="require('@/assets/company/01.png')"
              fit="fill">
            </el-image>
            <div class="service_container_content">
              <div class="service_top">
                <b>加盟服务</b>
                <time :class="['', 'blue', 'green', 'red', 'yellow'][tenantInfo.enteringAuditStatus]" v-if="tenantInfo.enteringAuditStatus">{{['', '审核中', '审核成功', '审核失败', '未提交'][tenantInfo.enteringAuditStatus]}}</time>
              </div>
              <div class="service_bottom">共享资源，专业技术支持</div>
            </div>
          </div>
          <!-- <div class="ser_bottom">
            <span>到期时间: 2024-09-09</span>
          </div> -->
        </div>
        <div >
          <div class="ser_top">
            <el-image
              style="width: 40px; height: 40px;margin-right:10px"
              :src="require('@/assets/company/03.png')"
              fit="fill">
            </el-image>
            <div class="service_container_content">
              <div class="service_top">
                <b>电子签</b>
                <!-- <time :class="['grey', 'grey', 'blue', 'green'][service.status]">{{['未开通', '已到期', '已开通', '使用中'][service.status]}}</time> -->
              </div>
              <div class="service_bottom">方便快捷，节约企业成本</div>
            </div>
          </div>
          <!-- <div class="ser_bottom">
            <span>到期时间: 2024-09-09</span>
          </div> -->
        </div>
      </div>
    </ContentBlock>
    <CreateBusinessDialog ref="createBusiness"></CreateBusinessDialog>
    <CompanyListDialog ref="selectBusiness"></CompanyListDialog>
  </div>
</template>

<script>
import SystemSetting, {getDetailTenantBaseInfo} from '@/api/systemSetting';
import { check } from '@/api/company';
import { CreateBusinessDialog, CompanyListDialog } from '@/components/Dialog';

export default {
  name: '',
  components: {
    CreateBusinessDialog,
    CompanyListDialog,
  },
  created () {
    this.getDetailInfo();
    this.judgeIsXiaoZi();
    this.getUserInfo();
  },
  methods: {
    judgeIsXiaoZi () {
      check({operateType: '1'}).then(({body})=>{
        this.isSupplier = (!!body.name) || false;
      });
    },
    getDetailInfo () {
      getDetailTenantBaseInfo().then(({body}) => {
        this.tenantInfo = body;
      });
    },
    async getUserInfo () {
      let { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
      const info = await SystemSetting.getDetailEmploy({
        findDepartmentFlag: true,
        findRoleFlag: true,
        id: employeeMsg.id,
      });
      this.userInfo = info.body || {};
    },
    handleViewMedial () {
      // 这里需要判断医修保 是否是个人版
      // if (this.userInfo.isPersonal) {
      //   let title = '请切换至企业版本';
      //   let ownerList = JSON.parse(this.$local.get('ownerList'));
      //   if (!(ownerList && ownerList.length)) {
      //     title = '请先创建企业';
      //   }
      //   this.$message.warning(title);
      //   return;
      // }
      // if (['use'].includes(this.tenantInfo.tenantServiceInfoStatus)) {
      //   this.$router.push({
      //     name: 'ORDERWALL',
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'JOIN_YIXIUBAO',
      //   });
      // }

      // 个人用户
      if (this.userInfo.isPersonal) {
        let ownerList = this.userInfo.adminTenantRespDTOList; // 是超级管理员的企业
        // 无企业 或者 有企业但不是企业管理员
        if (!(ownerList && ownerList.length)) {
          this.$refs.createBusiness.createdDialogVisible = true;
        } else {
          this.$refs.selectBusiness.dialogVisible = true;
        }
        return;
      }

      if (['use'].includes(this.tenantInfo.tenantServiceInfoStatus)) {
        this.$router.push({
          name: 'ORDERWALL',
        });
      } else {
        this.$router.push({
          name: 'JOIN_YIXIUBAO',
        });
      }
    },
    handleView () {
      // if (this.userInfo.isPersonal) {
      //   let title = '请切换至企业版本';
      //   let ownerList = JSON.parse(this.$local.get('ownerList'));
      //   if (!(ownerList && ownerList.length)) {
      //     title = '请先创建企业';
      //   }
      //   this.$message.warning(title);
      //   return;
      // }

      // 个人用户
      if (this.userInfo.isPersonal) {
        let ownerList = this.userInfo.adminTenantRespDTOList; // 是超级管理员的企业
        // 无企业 或者 有企业但不是企业管理员
        if (!(ownerList && ownerList.length)) {
          this.$refs.createBusiness.createdDialogVisible = true;
        } else {
          this.$refs.selectBusiness.dialogVisible = true;
        }
        return;
      }
      // 未认证企业
      if (this.authenticationStatus === '1') {
        this.$confirm('企业认证后才能开通小紫加盟服务，是否去认证？', '提示', {
          confirmButtonText: '去认证',
          cancelButtonText: '暂不认证',
          type: 'warning',
        }).then(() => {
          this.$router.push({
            name: 'IDENTIFICATION',
          });
        });
        return;
      }

      // 认证中企业
      if (this.authenticationStatus === '2') {
        this.$confirm('企业正在认证中，认证后才能开通小紫加盟服务，请耐心等待审核！', '提示', {
          confirmButtonText: '我知道了',
          showCancelButton: false,
          type: 'warning',
        });
        return;
      }

      if (this.isSupplier) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let tenantName = userInfo.departmentName;
        this.$confirm(`【${tenantName}】已是小紫合作供应商，暂时不能开通小紫加盟服务！`, '加盟提示', {
          showCancelButton: false,
          confirmButtonText: '知道了',
          type: 'warning',
        });
        return;
      }

      if (this.authenticationStatus === '3') {
        if (['4', null, '3'].includes(this.tenantInfo.enteringAuditStatus)) {
          this.$router.push({
            name: 'CorporateCertification',
          });
        } else {
          this.$router.push({
            name: 'CompanyDetail',
          });
        }
      }
    },

    // 跳转到1械网商城
    goYixie () {
      const environment = process.env.VUE_APP_OFFICAL_WEBSITE_YI;
      window.open(environment, '_blank');
    },
  },
  computed: {
    authenticationStatus () {
      return JSON.parse(this.$local.get('userInfo'))?.authenticationStatus || null;
    },
  },
  data () {
    return {
      tenantInfo: {},
      entryStatus: {},
      isSupplier: false,
      userInfo: {},
      statusOb: {
        wait: 'grey',
        run: 'blue',
        expire: 'grey',
        waitActive: 'grey',
        reject: 'grey',
        close: 'grey',
        use: 'green',
      },
      statusLabelOb: {
        wait: '待试用审核',
        run: '试用中',
        expire: '已到期',
        waitActive: '待激活审核',
        reject: '已驳回',
        close: '已关闭',
        use: '使用中',
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .serviceContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &>div{
    width: 272px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #EBEDF0;
    min-height: 80px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover{
      box-shadow: 2px 2px 4px 0px rgba(215,219,224,0.7);
    }
    .ser_top{
      display: flex;
      div.service_container_content{
        flex: 1;
        .service_top{
          letter-spacing: 0;
          text-align: left;
          line-height: 24px;
          b{
            font-size: 14px;
            color: #1F2733;
            margin-bottom: 0;
            font-weight: 600;
            margin-right: 6px;;
          }
          time{
            font-size: 12px;
            color: #237FFA;
            letter-spacing: 0;
            text-align: center;
            background: #E2F3FE;
            border-radius: 4px;
            line-height: 20px;
            padding: 2px 8px;
            height: 24px;
            line-height: 24px;
            font-weight: 400;
            &.grey{
              background: #F2F4F7;
              color: #1F2733;
            }
            &.blue{
              color: #237FFA;
              background: #E2F3FE;
            }
            &.green{
              color: #14CA64;
              background: #E5FEEB;
            }
            &.yellow{
              color: #FAB007;
              background: #FEFAE0;
            }
            &.red{
              color: #FF5959;
              background: #FEE9E5;
            }
          }

        }
        .service_bottom{
          font-size: 12px;
          color: #5F6A7A;
          letter-spacing: 0;
          text-align: left;
        }
      }
    }
    .ser_bottom{
      margin-top: 5px;
      span{
        padding:4px 5px;
        background-image: linear-gradient(90deg, #F2F4F7 0%, #FFFFFF 100%);
      }
    }
  }
}
</style>
