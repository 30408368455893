<template>
  <div>
    <!-- 选择企业 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeForbid"
      width="520px"
      class="select-business">
      <div slot="title" class="title">请先选择企业</div>
      <div class="xz-company-list" :style="{'max-height': maxHeight + 'px'}">
      <div @click="switchCurrentCompany(item)" v-for="(item, index) in dataList" :key="'comp-'+index" class="xz-company">
        <div class="company-name">
          <span class="head">{{ item.name ? item.name.substr(0,1) : '' }}</span>
          <div class="name">
            {{ item.name }}
            <img :src="filterIdentifyStatus(item.authenticationStatus)" />
          </div>
        </div>
        <img v-if="item.isCurrentTenant" :src="require('@/assets/icon_choosed.svg')" />
      </div>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import switchCompany from '@/utils/switchCompany';
export default {
  data () {
    return {
      statusList: [
        {
          status: '未认证',
          statusCode: '1',
          img: require('@/assets/ic_weirenzheng.svg'),
        },
        {
          status: '认证中',
          statusCode: '2',
          img: require('@/assets/ic_renzhengzhong.svg'),
        },
        {
          status: '已认证',
          statusCode: '3',
          img: require('@/assets/ic_yirenzheng.svg'),
        },
      ],
      dialogVisible: false,
      dataList: [],
      // companyLists: JSON.parse(this.$local.get('ownerList')),
      maxHeight: 300,
    };
  },
  computed: {
    companyLists () {
      return JSON.parse(this.$local.get('userInfo')).adminTenantRespDTOList;
    },
  },
  watch: {
    companyLists: {
      deep: true,
      immediate: true,
      handler () {
        let list = JSON.parse(JSON.stringify((this.companyLists)));
        this.dataList = list;
      },
    },
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),

    filterIdentifyStatus (status) {
      let statusIcon = '';
      this.statusList.map(item => {
        if (status === item.statusCode) {
          statusIcon = item.img;
        }
        return item;
      });
      return statusIcon;
    },

    async switchCurrentCompany (item) {
      this.dialogVisible = false;
      await switchCompany(item.id);
      this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));
      this.$tabs.items = [];
      this.$router.push({
        path: '/workbench',
      });
      window.location.reload();
      // if (!this.showAdditive) {
      //   this.$emit('refresh', item);
      // } else {
      //   this.$tabs.items = [];
      //   this.$router.push({
      //     path: '/workbench',
      //   });
      //   window.location.reload();
      // }
    },

    closeForbid () {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-business {
  .xz-company-list {
    min-height: 200px;
    overflow-y: scroll;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
}

.xz-company-tip {
  font-size: 14px;
  font-weight: bold;
  color: #1F2733;
  padding-top: 20px;
}
.xz-company-list {
  min-height: 200px;
  overflow-y: scroll;
}
.xz-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  .company-name {
    display: inline-flex;
    align-items: center;
    .head {
      font-size: 14px;
      font-weight: bold;
      color: #1A66FF;
      padding: 10px 13px;
      background: #E3ECFF;
      border-radius: 4px;
      margin-right: 5px;
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      >img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
  > img {
    flex-shrink: 0;
    margin-left: 10px;
    width: 16px;
    height: 16px;
  }
}
.xz-company:hover {
  background-color: #F2F4F7;
}
.xz-company-create {
  cursor: pointer;
  color: #237FFA;
  font-weight: bold;
  padding: 20px 10px 10px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  > img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

.create-company-tip {
  font-size: 12px;
  font-weight: 400;
  color: #929AA6;
  padding: 10px 0 20px;
}
</style>
